<template>
  <v-dialog
    :value="status.addDialog"
    no-click-animation
    max-width="450"
    persistent
  >
    <v-card
      :loading="searching"
      class="pa-5"
    >
      <v-btn @click="close()" icon absolute right top>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-sheet :max-width="$dialog.content" class="mx-auto">
        <v-card-title>
          <span class="text-h4 font-weight-black">{{ data.id ? 'Update User' : 'Add New User' }}</span>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-alert v-if="status.error" type="error" border="left" class="mb-7">{{ status.error }}</v-alert>

          <v-form ref="userForm" @submit.prevent="validateForm()">
            <div>
              <v-text-field
                v-model="data.firstName"
                :rules="[rules.required]"
                label="First Name"
                outlined
              ></v-text-field>
              
              <v-text-field
                v-model="data.lastName"
                :rules="[rules.required]"
                label="Last Name"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="data.email"
                :rules="[rules.required, rules.email]"
                label="Email"
                type="email"
                :disabled="(typeof data.id !== 'undefined')"
                outlined
              ></v-text-field>
            </div>

            <!-- USER DROPDOWN LIST -->
            <div>
              <v-select
                v-model="data.role"
                :items="userRoles.userList"
                :rules="[rules.required]"
                label="Role"
                outlined
              ></v-select>
            </div>

            <div class="d-flex gap-10">
              <div>
                <v-btn
                  @click="validateForm()"
                  :loading="status.adding"
                  color="accent"
                  class="gradient"
                >Submit</v-btn>
              </div>

              <v-btn
                @click="close()"
                depressed
              >Cancel</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-sheet>
      </v-card>
  </v-dialog>
</template>

<style lang="scss" scope>
.invite_tr:hover { background-color: #fff !important; }
.remove-btn { width: 1.2rem !important; height: 1.2rem !important; }
</style>

<script>

import { mapState, mapActions } from 'vuex'
import rules from '@/rules'

/* -------------------------------------------------------------------------- */
/*     User list dropdown for account and collaborator - default is account   */
/* -------------------------------------------------------------------------- */
export default {
  data() {
    return {
      rules,
      userType: null, 
      hasError: false,
      search: '',
      searching: false,
      userRoles: {
        userList: [
          'admin', 'jobseeker', 'employer'
        ]
      },
    }
  },

  computed: {
    ...mapState({
      data: state => state.users.data,
      status: state => state.users.status,
    }),

    filteredList() {
      if(this.searching){
        this.turnOffSearch()
        if(typeof this.users != 'object'){ return [] }
        return this.users.filter(user => {
          return user.fullName.toLowerCase().includes(this.search.toLowerCase())
        })
      }else{
        if(typeof this.users != 'object'){ return [] }
        return this.users
      }
    },

  },

  methods: {
    ...mapActions('users', [
      'addUser'
    ]),
    

    close() {
      this.$store.commit('users/setAddDialog', false)
      this.$store.commit('users/setData', {})
      this.$store.commit('users/setError',null)
      this.$refs.userForm.reset()
      this.searching = false
    },

    turnOffSearch() {
      this.searching = false;
    },

    validateForm(type = '') {
      if (this.$refs.userForm.validate()) {  
        if(type == ''){
          this.addUser()
        }
      }
    },

  },


  created() {
    
  }

}

</script>
