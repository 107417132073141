<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Users">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="getUsers()"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-btn
            @click="showAddEditDialog()"
            color="accent gradient"
            dark
          >
            Add New User
          </v-btn>

          <!-- <v-btn color="primary" outlined>
            Export CSV
          </v-btn> -->
        </div>
      </template>
    </AdminTitle>

    <div>
      <!-- FILTERS -->
      <div class="d-flex justify-space-between align-center mb-5">
        <div>
          Displaying total of <span class="font-weight-bold">{{ filtered.length }}</span> Users
        </div>

        <div class="d-flex gap-12 align-center">
          <v-sheet width="200">
            <v-select
              v-model="filter"
              :items="[
                { text: 'All', value: 'all' },
                { text: 'Admin', value: 'admin' },
                { text: 'Employer Active', value: 'employer_active' },
                { text: 'Employer Inactive', value: 'employer_inactive' },
                { text: 'Employer Trial', value: 'employer_trial' },
                { text: 'Teacher', value: 'jobseeker' },
              ]"
              full-width
              outlined
              dense
            ></v-select>
          </v-sheet>
        </div>
      </div>

      <template v-if="status.getting">
        <preloader/>
      </template>

      <!-- CONTENT -->
      <v-card class="shadow-lg" v-if="!status.getting">
        <v-card-text>
          <v-alert v-if="!users.length" type="info" border="left" class="mb-0">No users found.</v-alert>
          
          <v-data-table
            :headers="headers"
            :items="filtered"
            :search="search"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                class="mt-4"
                clearable
                outlined
                dense
              ></v-text-field>
            </template>

            <template v-slot:item.photo="{ item }">
              <UserPhoto :size="30" photoSize="thumb" :id="item.userid" class="mr-3" />
            </template>

            <template v-slot:item.role="{ item }">
              {{ item.role == 'jobseeker' ? 'teacher' : item.role }}
            </template>
            
            <template v-slot:item.actions="{ item }">
              <v-menu bottom left offset-y transition="slide-x-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    small
                  >
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item :to="{ name: item.role == 'jobseeker' ? 'TalentProfile' : 'EmployerProfile', params: { id: item.userid } }" class="subtle" dense>
                    <v-list-item-title class="primary--text"><v-icon left small>mdi-account-settings-outline</v-icon> View Profile</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showAddEditDialog(item)" class="subtle" dense>
                    <v-list-item-title class="primary--text"><v-icon left small>mdi-account-edit-outline</v-icon> Edit</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="deleteUser(item)" class="subtle" dense>
                    <v-list-item-title><v-icon color="red" left small>mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <div v-if="!status.getting">
        <template v-if="users.length && this.listView == 'grid'">
          <v-row>
            <v-col cols="3" v-for="user in ordered" :key="user.id">
              <v-card outlined hover>
                <v-card-text class="fill-height align-center">
                  <div>
                    <div class="d-flex justify-space-between">
                      <UserPhoto :size="90" photoSize="thumb" :id="user.userid" />
                      <v-chip label x-small :color="getLabelColor(user.role)" >{{ user.role }}</v-chip>
                    </div>
                      <div class="d-flex justify-space-between">
                        <h5 class="body-2 font-weight-bold pt-3">{{ user.fullName }}</h5>
                      </div>
                      <div class="d-flex justify-space-between">
                        <a :href="`mailto: ${user.email}`" class="text-decoration-none">{{ user.email }}</a>
                      </div>

                  </div>
                </v-card-text>
                <v-card-actions class="grey lighten-4 align-center">
                  <div>
                      <v-icon left small>mdi-calendar-month-outline</v-icon> <span class="caption">{{ user.createdAt | fromNow }}</span>
                  </div>
                  <v-spacer></v-spacer>
                  <div>
                    <v-menu bottom right offset-y transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          v-on="on"
                          small
                        >
                          <v-icon small>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-settings-outline</v-icon> View</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showAddEditDialog(user)" class="subtle" dense>
                          <v-list-item-title class="primary--text"><v-icon left small>mdi-account-edit-outline</v-icon> Edit</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="deleteUser(user)" class="subtle" dense>
                          <v-list-item-title><v-icon color="red" left small>mdi-delete-outline</v-icon> <span class="red--text">Delete</span> </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </div>

    </div>

    <!-- ADD EDIT DIALOG -->
    <AddEditDialog />

    <!-- CONFIRM PASSWORD -->
    <ConfirmPassword />

    <!-- CONFIRM DELETE -->
    <ConfirmDelete 
      :show="confirm" 
      :message="deleteMessage" 
      :deleting="status.deleting" 
      @cancel="confirm = false" 
      @confirmed="deleteConfirmed()" 
    />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import AddEditDialog from '@/components/user/AddEditUser'
import ConfirmPassword from '@/components/auth/ConfirmPassword'
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

export default {
  name: 'Users',

  metaInfo: {
    title: 'Users'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      moment,
      confirm: false,
      deleteMessage: null,
      toDelete: null,
      order: 'createdAt',
      listView: 'list',
      filter: 'all',
      search: '',
      headers: [
        { text: 'Photo', value: 'photo' },
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
      orderBy: [
        {
          name: 'Date Created',
          value: 'createdAt'
        },
        {
          name: 'Name',
          value: 'fullName'
        }
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    AddEditDialog,
    ConfirmPassword
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      users: state => state.users.users,
      status: state => state.users.status,
    }),

    filtered: function () {
      let users = this.users

      if (this.filter !== 'all') {

        // USERS ON FREE TRIAL FILTER
        if (this.filter == 'employer_trial') {
          users = users.filter(u => {
            return u.role == 'employer' && this.freeTrial(u)
          })
        }
        // USERS ON WITH ACTIVE SUBSCRIPTION
        else if (this.filter == 'employer_active') {
           users = users.filter(u => {
            return u.role == 'employer' && this.$store.getters['subscription/withActiveSubscription'](u)
          })
        }
        // USERS ON WITH ACTIVE SUBSCRIPTION
        else if (this.filter == 'employer_inactive') {
           users = users.filter(u => {
            return u.role == 'employer' && !this.$store.getters['subscription/withActiveSubscription'](u) && !this.freeTrial(u)
          })
        }
        else {
          users = users.filter(user => user.role == this.filter)
        }
      }

      return _orderBy(users, 'createdAt', 'desc')
    }

  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('users', [
      'getUsers',
      'deleteAuthUser',
    ]),
    
    ...mapActions('subscription', [
      'getCustomers',
    ]),

    showAddEditDialog(user = null) {
      this.$store.commit('users/setAddDialog', true)

      if (user) {
        this.$store.commit('users/setData', user)
      }
    },

    deleteUser(user) {
      this.confirm = true
      this.toDelete = user
      this.deleteMessage = `Are you sure you want to delete user <strong>${user.email}</strong>?`
    },

    deleteConfirmed() {
      Promise.all([
        this.deleteAuthUser(this.toDelete)
      ])
      .then(() => {
        this.confirm = false
        this.toDelete = null
        this.deleteMessage = null
      })
    },

    changeView( active ) {
      this.listView = active
    },

    freeTrial(user) {
      return user.trialEnd > Date.now()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoad) this.getUsers()
    this.getCustomers()
  }

}

</script>

<style media="screen">

</style>
